<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
            enctype="multipart/form-data"
          >
            <b-card>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="form.name"
                        :state="errors.length > 0 ? false : null"
                        name="name"
                        placeholder="Enter Your Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group label="Phone Number*" label-for="number">
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        :disabled="true"
                        id="number"
                        v-model="form.number"
                        :state="errors.length > 0 ? false : null"
                        name="number"
                        placeholder="Enter Your Phone Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group label="Email" label-for="Email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="email"
                    >
                      <b-form-input
                        v-model="form.email"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group label="Select Payment Type" label-for="type">
                    <validation-provider
                      #default="{ errors }"
                      name="Payment Type"
                      rules="required"
                    >
                      <v-select
                        :disabled="number != null"
                        id="type"
                        v-model="form.payment_status"
                        placeholder="Select Payment Type"
                        :options="types"
                        label="name"
                        :reduce="(option) => option.value"
                      >
                        <template #option="{ name }">
                          <span> {{ name }}</span>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-3">
                  <b-form-group
                    :label="
                      (form.payment_status == 2 ? 'Nogod' : 'B-Kash') +
                      ' Account Number*'
                    "
                    label-for="number"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Account Number"
                      rules="required"
                    >
                      <b-form-input
                        :disabled="number != null"
                        id="payment_number"
                        v-model="form.payment_number"
                        :state="errors.length > 0 ? false : null"
                        name="payment_number"
                        :placeholder="
                          'Enter Your ' +
                          (form.payment_status == 2 ? 'Nogod' : 'B-Kash') +
                          ' Account Number*'
                        "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <b-button
                type="submit"
                class="float-right"
                variant="primary"
                @click="validationForm"
              >
                Update
              </b-button>
            </b-card>
          </b-form>
        </validation-observer>
      </div>
      <div class="col-md-4">
        <validation-observer ref="passwordChange">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
            enctype="multipart/form-data"
          >
            <b-card>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Old Password</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Old Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="form.old_password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">New Password</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="form.password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Confirm Password</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required|confirmed:New Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="password_confirmation"
                          v-model="form.password_confirmation"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="password_confirmation"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <b-button
                type="submit"
                class="float-right"
                variant="primary"
                @click="validationPasswordChange"
              >
                Update
              </b-button>
            </b-card>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BFormTextarea,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { required, email, min, confirmed, password } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    vSelect,
  },
  data() {
    return {
      required,
      email,
      min,
      confirmed,
      snowOption: {
        minHeight: 200,
      },
      types: [
        {
          value: 1,
          name: "BKash",
        },
        {
          value: 2,
          name: "Nogod",
        },
      ],
      form: {
        name: "",
        email: "",
        number: "",
        payment_status: "",
        password: "",
        password_confirmation: "",
        old_password: "",
        status: true,
      },
      number: null,
      modules: {},
      roles: [],
      permission: {
        access: false,
      },
    };
  },
  mixins: [togglePasswordVisibility],
  mounted() {
    this.show();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    async show() {
      const user = await this.callApi("post", "/affiliate/show/profile");
      if (user.status == 200) {
        this.form.name = user.data.user.name;
        this.form.number = user.data.user.number;
        this.form.email = user.data.user.email;
        this.form.payment_status = user.data.user.payment_status;
        this.form.payment_number = user.data.user.payment_number;
        this.number = user.data.user.payment_number;
      }
    },
    add() {
      axios
        .post("/affiliate/update/profile", this.form)
        .then((res) => {
          this.s(res.data.message);
          this.show();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name) {
              this.e(e.response.data.errors.name[0]);
            }
            if (e.response.data.errors.email) {
              this.e(e.response.data.errors.email[0]);
            }
            if (e.response.data.errors.password) {
              this.e(e.response.data.errors.password[0]);
            }
            if (e.response.data.errors.password_confirmation) {
              this.e(e.response.data.errors.password_confirmation[0]);
            }
          }
        });
    },

    updatePassword() {
      axios
        .post("/affiliate/update/password", this.form)
        .then((res) => {
          if (res.data.status == 1) {
            this.form.password = null;
            this.form.password_confirmation = null;
            this.form.old_password = null;
            this.s(res.data.message);
          } else {
            this.e(res.data.message);
          }
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name) {
              this.e(e.response.data.errors.name[0]);
            }
            if (e.response.data.errors.email) {
              this.e(e.response.data.errors.email[0]);
            }
            if (e.response.data.errors.password) {
              this.e(e.response.data.errors.password[0]);
            }
            if (e.response.data.errors.password_confirmation) {
              this.e(e.response.data.errors.password_confirmation[0]);
            }
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },
    validationPasswordChange() {
      this.$refs.passwordChange.validate().then((success) => {
        if (success) {
          this.updatePassword();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image_div {
  position: relative;
}
.image_delete {
  width: 25px;
  height: 25px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 30px;
  background: rgba(0, 0, 0, 0.548);
  cursor: pointer;
  border-radius: 50%;
}
</style>
